<template>
  <v-card>
    <v-card-title> ธนาคาร </v-card-title>
    <v-divider />
    <v-card-text class="pa-0 pb-8">
      <v-data-table
        mobile-breakpoint="0"
        :headers="headers"
        :items="accountBanks"
        :no-data-text="$t('not_found')"
        :no-results-text="$t('not_found')"
        disable-pagination
        hide-default-footer
      >
        <template v-slot:[`item.bank`]="{ item }">
          <img v-if="item.bank" alt="Avatar" width="25px" class="pt-3" :src="require(`@/assets/images/logos/banks/${item.bank}.png`)" />
          <br />
          <span>{{ item.bank_account }}</span>
        </template>
        <template class="text-right" v-slot:[`item.balance`]="{ item }">
          <span>{{ item.balance | currency }}</span>
        </template>
        <template class="text-right" v-slot:[`item.type`]="{ item }">
          <span>{{ item.type === 'withdraw' ? 'ถอน' : 'ฝาก' }}</span>
        </template>
      </v-data-table>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: 'รายการถอน',
    },
    accountBanks: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      headers: [
        {
          text: 'ธนาคาร',
          value: 'bank',
          align: 'center',
          sortable: false,
        },
        {
          text: 'ชื่อบัญชี',
          value: 'bank_name',
          sortable: false,
        },
        {
          text: 'ประเภท',
          value: 'type',
          align: 'center',
          sortable: false,
        },
        {
          text: 'ยอดคงเหลือ',
          value: 'balance',
          align: 'end',
          sortable: false,
        },
      ],
    }
  },
}
</script>
