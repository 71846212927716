<template>
  <v-card>
    <v-card-title>
      <span class="me-3 error--text">
        {{ title }}
      </span>
      <v-card-text v-if="reportType === 'daily'" class="pa-0 pt-2">
        {{ $t('dashboard.block8_month')}} {{ currentMonthYear }}
      </v-card-text>
    </v-card-title>
    <v-card-text v-if="reportType === 'daily'" class="pt-0">
      ยอดผู้เล่นจะอัพเดตทุก 1:00 AM (ตี 1) ของวันถัดไป
    </v-card-text>
    <v-divider />
    <v-card-actions class="pa-0">
      <v-data-table
        mobile-breakpoint="0"
        style="width: 100%"
        :headers="headers"
        :items="reports"
        :no-data-text="$t('not_found')"
        :no-results-text="$t('not_found')"
        hide-default-footer
        disable-pagination
      >
        <template v-slot:[`item.deposit`]="{ item }">
          <span>{{ item.deposit | currency }}</span>
        </template>
        <template v-slot:[`item.withdraw`]="{ item }">
          <span>{{ item.withdraw | currency }}</span>
        </template>
        <template v-slot:[`item.profit`]="{ item }">
          <span>{{ item.profit | currency }}</span>
        </template>
      </v-data-table>
    </v-card-actions>

    <div v-if="reportType === 'daily'" class="pb-8">
      <div style="border-top: 1px solid #ddd"></div>
      <div class="v-data-table theme--light" style="width: 100%">
        <div class="v-data-table__wrapper">
          <table>
            <tbody>
              <tr style="border-top: 1px solid #ccc">
                <td>
                  <span style="font-weight: bold">กำไรสุทธิ</span>
                </td>
                <td class="col-1">
                  <span style="font-weight: bold">{{ totalProfit | currency }}</span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </v-card>
</template>

<script>
export default {
  props: {
    reportType: {
      type: String,
      default: 'daily',
    },
    title: {
      type: String,
      default: '',
    },
    reports: {
      type: Array,
      default: () => [],
    },
    totalProfit: {
      type: Number,
      default: 0,
    },
    currentMonthYear: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      dailyHeaders: [
        {
          text: this.$t('dashboard.block7_date'),
          value: 'day',
          sortable: false,
        },
        {
          text: 'ยอดผู้เล่น',
          value: 'active_users',
          align: 'end',
          sortable: false,
        },
        {
          text: this.$t('dashboard.block7_deposit'),
          value: 'deposit',
          align: 'end',
          sortable: false,
        },
        {
          text: this.$t('dashboard.block7_withdraw'),
          value: 'withdraw',
          align: 'end',
          sortable: false,
        },
        {
          text: this.$t('dashboard.block7_net_profit'),
          value: 'profit',
          align: 'end',
          sortable: false,
        },
      ],
      monthlyHeaders: [
        {
          text: this.$t('dashboard.block8_month'),
          value: 'month',
          sortable: false,
        },
        {
          text: this.$t('dashboard.block8_deposit'),
          value: 'deposit',
          align: 'end',
          sortable: false,
        },
        {
          text: this.$t('dashboard.block8_withdraw'),
          value: 'withdraw',
          align: 'end',
          sortable: false,
        },
        {
          text: this.$t('dashboard.block8_net_profit'),
          value: 'profit',
          align: 'end',
          sortable: false,
        },
      ],
    }
  },
  created() {},
  computed: {
    headers() {
      return this.reportType === 'daily' ? this.dailyHeaders : this.monthlyHeaders
    },
  },
}
</script>
