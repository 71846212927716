<template>
  <v-card>
    <div class="d-flex flex-sm-row flex-column">
      <div class="flex-grow-1">
        <v-card-title>
          <span class="me-3 error--text">{{ title }}</span>
          <v-spacer></v-spacer>
        </v-card-title>
        <v-divider />
        <v-card-text class="pa-0 pb-8">
          <v-data-table
            :headers="headers"
            mobile-breakpoint="0"
            :items="transection"
            :no-data-text="$t('not_found')"
            :no-results-text="$t('not_found')"
            disable-pagination
            hide-default-footer
          >
            <!-- <template v-slot:[`item.status`]="{ item }">
                :class="`${
                  item.status === 'denied' ? 'error' : item.status === 'wait_member' ? 'warning' : 'success'
                } popout`"
              ></div>
              <span>{{
                item.status === 'denied' ? 'ไม่อนุมัติ' : item.status === 'wait_member' ? 'รออนุมัติ' : 'อนุมัติ'
              }}</span>
            </template> -->
            <!-- <template v-slot:[`item.bank`]="{ item }">
              <span>{{ formatDate(item.created_at) }}</span>
            </template> -->
            <template v-slot:[`item.created_at`]="{ item }">
              <span>{{ formatDate(item.created_at) }}</span>
            </template>
            <template v-slot:[`item.bank`]="{ item }">
              <img
                v-if="item.bank"
                alt="Avatar"
                width="25px"
                class="pt-3"
                :src="require(`@/assets/images/logos/banks/${item.bank}.png`)"
              />
              <br />
              <span>{{ item.bank_account }}</span>
            </template>
            <template v-slot:[`item.amount`]="{ item }">
              <span>{{ item.amount | currency }}</span>
            </template>
          </v-data-table>
        </v-card-text>
      </div>
    </div>
  </v-card>
</template>

<script>
import moment from 'moment'

export default {
  props: {
    title: {
      type: String,
      default: 'รายการฝาก',
    },
    transection: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      headers: [
        {
          text: 'ชื่อ',
          value: 'bank_name',
          width: '100px',
          sortable: false,
        },
        {
          text: 'ธนาคาร',
          value: 'bank',
          align: 'center',
          sortable: false,
        },
        // {
        //   text: 'เลขที่บัญชี',
        //   value: 'bank_account',
        //   sortable: false,
        // },
        {
          text: 'ฝากเมื่อ',
          value: 'created_at',
          sortable: false,
        },
        {
          text: 'จำนวนเงิน',
          value: 'amount',
          align: 'end',
          sortable: false,
        },
      ],
    }
  },
  methods: {
    formatDate(date) {
      return moment(date).format('YY-MM-DD HH:mm')
    },
  },
}
</script>

<style scoped>
.popout {
  border-radius: 50%;
  width: 15px;
  height: 15px;
  animation: popout 2s infinite;
  -webkit-animation: popout 1s infinite;
  aspect-ratio: 1/1;
}
@keyframes popout {
  from {
    transform: scale(0);
  }
  80% {
    transform: scale(1.2);
  }
  to {
    transform: scale(1);
  }
}
@-webkit-keyframes popout {
  from {
    -webkit-transform: scale(0);
  }
  80% {
    -webkit-transform: scale(1.2);
  }
  to {
    -webkit-transform: scale(1);
  }
}
</style>
