<template>
  <v-row class="dashboard-row">
    <v-col cols="12" md="12">
      <dashboard-card-member-summary :currentDate="currentDate" :report="todayReport" :members="memberInfo"
        :accountBanks="accountBankReport" :depositTransaction="depositReport"
        :withdrawTransaction="withdrawReport"></dashboard-card-member-summary>
    </v-col>

    <v-col cols="12" md="6">
      <dashboard-card-summary-report reportType="daily" :title="$t('dashboard.block7_head')" :reports="dailyReports" :totalProfit="totalProfit" :currentMonthYear="currentMonthYear" />
    </v-col>
    <v-col cols="12" md="6">
      <dashboard-card-summary-report reportType="monthly" :title="$t('dashboard.block8_head')"
        :reports="monthlyReports" />
    </v-col>

    <!-- <v-col cols="12" md="4">
      <dashboard-card-profits-today :report="todayReport" />
    </v-col>
    <v-col cols="12" md="2">
      <dashboard-card-members :members="memberInfo" />
    </v-col>
    <v-col cols="12" md="6">
      <dashboard-card-banks :accountBanks="accountBankReport" />
    </v-col>
    <v-col cols="12" md="6">
      <dashboard-card-withdraw :transection="withdrawReport" />
    </v-col>
    <v-col cols="12" md="6">
      <dashboard-card-deposit title="รายการฝาก" :transection="depositReport" />
    </v-col>
    <v-col cols="12" md="6">
      <dashboard-card-summary-report reportType="daily" title="สรุปรายวัน" :reports="dailyReports" />
    </v-col>
    <v-col cols="12" md="6">
      <dashboard-card-summary-report reportType="monthly" title="สรุปรายเดือน" :reports="monthlyReports" />
    </v-col>
    <v-col cols="12" md="6">
      <dashboard-card-affiliate-total-play :report="affiliateTotalReport" />
    </v-col> -->
  </v-row>
</template>

<script>
import axios from 'axios'
// eslint-disable-next-line object-curly-newline
import { mdiPoll, mdiLabelVariantOutline, mdiCurrencyUsd, mdiHelpCircleOutline } from '@mdi/js'
import StatisticsCardVertical from '@/components/statistics-card/StatisticsCardVertical.vue'

// demos
import DashboardCardProfitsToday from './DashboardCardProfitsToday.vue'
import DashboardCardMembers from './DashboardCardMembers.vue'
import DashboardCardBanks from './DashboardCardBanks.vue'
import DashboardCardWithdraw from './DashboardCardWithdraw.vue'
import DashboardCardDeposit from './DashboardCardDeposit.vue'
import DashboardCardSummaryReport from './DashboardCardSummaryReport.vue'
import DashboardCardAffiliateTotalPlay from './DashboardCardAffiliateTotalPlay.vue'

import DashboardCongratulationJohn from './DashboardCongratulationJohn.vue'
import DashboardStatisticsCard from './DashboardStatisticsCard.vue'
import DashboardCardTotalEarning from './DashboardCardTotalEarning.vue'

import DashboardCardSalesByCountries from './DashboardCardSalesByCountries.vue'
import DashboardWeeklyOverview from './DashboardWeeklyOverview.vue'
import DashboardDatatable from './DashboardDatatable.vue'

// new card
import DashboardCardMemberSummary from './DashboardCardMemberSummary.vue'

import moment from 'moment-timezone'
import store from '@/store'
import { getFormattedTime } from '@/utils/dateTimeUtils'

export default {
  components: {
    StatisticsCardVertical,
    DashboardCardProfitsToday,
    DashboardCardMembers,
    DashboardCardBanks,
    DashboardCardWithdraw,
    DashboardCardDeposit,
    DashboardCardAffiliateTotalPlay,

    DashboardCardSummaryReport,
    DashboardCongratulationJohn,
    DashboardStatisticsCard,
    DashboardCardTotalEarning,

    DashboardCardSalesByCountries,
    DashboardWeeklyOverview,
    DashboardDatatable,
    DashboardCardMemberSummary,
  },
  data() {
    return {
      todayReport: {
        total_amount_deposit: 0,
        total_amount_withdraw: 0,
        total_amount_profit: 0,
        total_member_register: 0,
        count_deposit: 0,
        count_withdraw: 0,
      },
      memberInfo: {
        all_member: 0,
        member_deposit: 0,
        member_not_deposit: 0,
      },
      accountBankReport: [],
      depositReport: [],
      withdrawReport: [],
      dailyReports: [],
      dailyReportsV2: [],
      monthlyReports: [],
      flag_interval: null,
      affiliateTotalReport: {
        total_play_all: 0,
        total_play_sport: 0,
        total_play_slot: 0,
        total_play_casino: 0,
      },
      totalProfit: 0,
      currentDate: '',
      currentMonthYear: '',
      bankSystem: ''
    }
  },
  async created() {
    await this.getWebSettingInfo()
    await this.addLogPage()
    await this.fetch_data()
    // this.socketSubscribe()
    // this.refreshdata()
  },
  methods: {
    async getWebSettingInfo() {
      try {
        let settingWebInfo = await this.$store.dispatch('getSettingWebInfo')
        this.bankSystem = settingWebInfo?.bank_system[0] || 'TH';
      } catch (e) { }
    },
    async addLogPage() {
      try {
        let data
        const userSystem = store.getters.getuserInfo
        await axios.get('https://api.ipify.org?format=json').then(res =>
          (data = {
            ip: res.data.ip,
            name: 'Dashboard',
            url: window.location.href,
            detail: 'หน้าแรก',
            admin: userSystem.name ? userSystem.name : '',
          }),
        )
        await this.$store.dispatch('addLogPage', data)
      } catch (e) {
        // if (!e.message.includes('Unexpected token') || !e?.includes('Unexpected token') || !e.message.includes('Unexpected identifier')) {
        //   this.$swal.fire({
        //     icon: 'error',
        //     title: e.message,
        //   })
        // }
      }
    },
    socketSubscribe() {
      // this.$socket.$subscribe('update_accountbank', data => {
      //   console.log(data)
      //   let index = this.accountBankReport.findIndex(obj => obj.id == data.id)
      //   this.accountBankReport[index] = data
      // })
      // this.$socket.$subscribe('new_deposit', data => {
      //   console.log(data)
      //   this.depositReport.unshift(data)
      // })
      // this.$socket.$subscribe('update_deposit', data => {
      //   console.log(data)
      //   let index = this.depositReport.findIndex(obj => obj.id == data.id)
      //   this.depositReport[index] = data
      // })
      // this.$socket.$subscribe('update_withdraw', data => {
      //   console.log(data)
      //   let index = this.withdrawReport.findIndex(obj => obj.id == data.id)
      //   this.withdrawReport[index] = data
      // })
      // this.$socket.$subscribe('new_withdraw', data => {
      //   console.log(data)
      //   this.withdrawReport.unshift(data)
      // })
    },
    async fetch_data() {
      try {
        if (this.$route.name === 'dashboard') {
          this.$store.dispatch('dashboardToday').then(result => {
            this.todayReport = result
          })
          this.$store.dispatch('dashboardAccountbank').then(result => {
            result.forEach((element) => {
              if (element?.bank_code) {
                const code = element.bank_code;
                const bankCodeData = this.checkBankCode(code)
                const { splitCountry, splitBankCode } = bankCodeData;

                element.bankCountry = splitCountry
                element.bank = splitBankCode
              }
            })
            this.accountBankReport = result
          })
          this.$store.dispatch('dashboardDeposit').then(result => {
            result.forEach((element) => {
              if (element?.bank_code) {
                const code = element.bank_code;
                const bankCodeData = this.checkBankCode(code)
                const { splitCountry, splitBankCode } = bankCodeData;

                element.bankCountry = splitCountry
                element.bank = splitBankCode
              }
            })
            this.depositReport = result
          })
          this.$store.dispatch('dashboardWithdraw').then(result => {
            result.forEach((element) => {
              if (element?.bank_code) {
                const code = element.bank_code;
                const bankCodeData = this.checkBankCode(code)
                const { splitCountry, splitBankCode } = bankCodeData;

                element.bankCountry = splitCountry
                element.bank = splitBankCode
              }
            })
            this.withdrawReport = result
          })
          this.$store.dispatch('dashboardDaily').then(result => {
            let resData = result
            let totalProfit = 0
            for (let i = 0; i < resData.length; i++) {
              if (resData[i]) {
                resData[i].active_users = resData[i]?.active_users || 0
                resData[i].day = moment(resData[i].day).format('D')
                totalProfit +=  parseFloat(resData[i].profit)
              }
            }
            this.dailyReports = resData
            this.totalProfit = totalProfit
            this.currentMonthYear = moment().format('MM-YY')
          })
          this.$store.dispatch('dashboardMonthly').then(result => {
            this.monthlyReports = result
            this.monthlyReports.forEach((el, i) => {
              this.monthlyReports[i].month = moment(this.monthlyReports[i].month).format('MM-YY')
            })
          })
          // this.$store.dispatch('dashboardAffiliateTotalPlay').then(result => {
          //   let resultData = {
          //     total_play_all: 0,
          //     total_play_sport: 0,
          //     total_play_slot: 0,
          //     total_play_casino: 0,
          //   }
          //   const totalData = result?.data
          //   resultData.total_play_all = result.all_total
          //   for (let dt of totalData) {
          //     if (dt.type_game === 'CASINO') {
          //       resultData.total_play_casino = dt.total_play
          //     } else if (dt.type_game === 'SLOT') {
          //       resultData.total_play_slot = dt.total_play
          //     } else if (dt.type_game === 'SPORT') {
          //       resultData.total_play_sport = dt.total_play
          //     }
          //   }

          //   this.affiliateTotalReport = resultData
          // })

          this.$store.dispatch('dashboardMemberInfo').then(result => {
            this.memberInfo = result
          })
        } else {
          clearInterval(this.flag_interval)
        }


        this.tz = this.$store.getters.timezone
        this.currentDate = getFormattedTime(new Date(), this.tz, 'YYYY-MM-DD HH:mm:ss')
      } catch (e) { }
    },
    checkBankCode(bankCode){
      const code = bankCode;
      let splitCountry, splitBankCode;

      const checkCountryCode = (code) => {
        const validCodes = ['TH', 'KR', 'LA', 'TW', 'CN', 'KH', 'VN', 'MM'];
        const firstTwoChars = code.substring(0, 2);
        return validCodes.includes(firstTwoChars);
      }

      if (checkCountryCode(code)) {
        splitCountry = code.slice(0, 2);
        splitBankCode = code.slice(2);
      } else {
        splitCountry = this.bankSystem;
        splitBankCode = code;
      }

      splitCountry = splitCountry.toLowerCase()
      return { splitCountry, splitBankCode }
    },
    refreshdata() {
      this.flag_interval = setInterval(() => {
        this.fetch_data()
      }, 60000)
    },
  },
}
</script>

<style scoped>
@media (max-width: 550px) {
  .dashboard-row {
    width: 100vw !important;
  }

  .dashboard-row .col-12 {
    padding: 6px 0px 6px 8px;
  }

  .dashboard-row .col-12:first-child {
    margin-top: 12px !important;
  }

  .dashboard-row .col-12:last-child {
    margin-bottom: 12px !important;
  }
}
</style>
