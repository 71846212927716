<template>
  <v-card>
    <v-card-actions class="justify-center">
      <v-avatar color="#E1F5FE">
        <v-icon size="30px" color="#4DD0E1"> mdi-account-multiple-outline </v-icon>
      </v-avatar>
    </v-card-actions>
    <v-card-title class="justify-center text-h6 py-0">
      {{ members.all_member.toLocaleString("en-US") }}
    </v-card-title>
    <v-card-text class="text-center pt-3"> สมาชิกทั้งหมด </v-card-text>
    <v-card-title class="justify-center text-h6 py-0">
      {{ members.member_deposit.toLocaleString("en-US") }}
    </v-card-title>
    <v-card-text class="text-center pt-3"> สมาชิกที่ฝากเงินแล้ว </v-card-text>
    <v-card-title class="justify-center text-h6 py-0">
      {{ members.member_not_deposit.toLocaleString("en-US") }}
    </v-card-title>
    <v-card-text class="text-center pt-3"> สมาชิกที่ยังไม่ฝากเงิน </v-card-text>
  </v-card>
</template>

<script>
export default {
  props: {
    members: {
      all_member: 0,
      member_deposit: 0,
      member_not_deposit: 0,
    },
  },
}
</script>